import React from 'react';
import { Jumbotron, Container, Col, Row } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const windowGlobal = typeof window !== 'undefined' && window;

const servicesCards = [
  {
    _id: 'service_card_1',
    title: 'Web',
    img: 'websites',
    card_in: 'translate-image-in',
    co_services: [
      {
        _id: 'service_1_co_service_1',
        name: 'Landing Pages',
      },
      {
        _id: 'service_1_co_service_2',
        name: 'E-commerce Websites',
      },
      {
        _id: 'service_1_co_service_3',
        name: 'Web Apps',
      },
      {
        _id: 'service_1_co_service_4',
        name: 'UI /UX Design',
      },
      {
        _id: 'service_1_co_service_5',
        name: 'Prototyping',
      },
      {
        _id: 'service_1_co_service_6',
        name: 'Testing',
      },
    ],
    description: 'Marketing websites, landing pages, e-commerce portals that communicate the compelling value proposition of your business.',
  },
  {
    _id: 'service_card_2',
    img: 'mobileApps',
    card_in: 'translate-image-in',
    title: 'Mobile',
    co_services: [
      {
        _id: 'service_2_co_service_1',
        name: 'UI /UX Design',
      },
      {
        _id: 'service_2_co_service_2',
        name: 'Prototyping',
      },
      {
        _id: 'service_2_co_service_3',
        name: 'Development - Android and iOS',
      },
      {
        _id: 'service_2_co_service_4',
        name: 'Testing',
      },
    ],
    description: 'Intuitive web apps that help improve your operational efficiency, gain insights optimize internal processes and boost revenues.',
  },
  {
    _id: 'service_card_3',
    img: 'chatBots',
    card_in: 'translate-image-in',
    title: 'Chatbots',
    co_services: [
      {
        _id: 'service_3_co_service_1',
        name: 'Conversational Design',
      },
      {
        _id: 'service_3_co_service_2',
        name: 'NLP Training',
      },
      {
        _id: 'service_3_co_service_3',
        name: 'Serverless Chatbots',
      },
      {
        _id: 'service_3_co_service_4',
        name: 'Multi channel chatbots',
      },
    ],
    description: 'Design & development of iOS and Android apps with amazing user experience and functionality.',
  },
];

const Image = ({ image }) => {
  return <Img fluid={image} alt="img" />;
};

const ServiceSection = ({ images }) => {
  const [showingSlide, setShowingSlide] = React.useState(-1);
  const [headWidths, setHeadWidths] = React.useState(Array(servicesCards.length).fill(0));
  const vh = windowGlobal.innerHeight;
  const vw = windowGlobal.innerWidth;
  let containerTop = 10;

  const startOnScroll = () => {
    const cardDiv1 = document.getElementById('service_card_1') && document.getElementById('service_card_1').getBoundingClientRect();
    const cardDiv2 = document.getElementById('service_card_2') && document.getElementById('service_card_2').getBoundingClientRect();
    const cardDiv3 = document.getElementById('service_card_3') && document.getElementById('service_card_3').getBoundingClientRect();
    // const cardDiv4 = document.getElementById('service_card_4') && document.getElementById('service_card_4').getBoundingClientRect();
    // const cardDiv5 = document.getElementById('service_card_5') && document.getElementById('service_card_5').getBoundingClientRect();
    const cardDiv1Img = document.getElementById('img_service_card_1');
    const cardDiv2Img = document.getElementById('img_service_card_2');
    const cardDiv3Img = document.getElementById('img_service_card_3');

    if (cardDiv1 && cardDiv1.top > 0) {
      setShowingSlide(-1);
    } else if (cardDiv1 && cardDiv1.top <= 0 && cardDiv1.top > -vh / 4) {
      setShowingSlide(0);
      if (cardDiv1Img && !cardDiv1Img.className.includes('translate-image-in')) cardDiv1Img.classList.add('translate-image-in');
    } else if (cardDiv2 && cardDiv2.top <= vh / 4 && cardDiv2.top > -vh / 4) {
      if (cardDiv2Img && !cardDiv2Img.className.includes('translate-image-in')) cardDiv2Img.classList.add('translate-image-in');
      setShowingSlide(1);
    } else if (cardDiv3 && cardDiv3.top <= vh / 4 && cardDiv3.top > -vh / 4) {
      if (cardDiv3Img && !cardDiv3Img.className.includes('translate-image-in')) cardDiv3Img.classList.add('translate-image-in');
      setShowingSlide(2);
    }
    // else if (cardDiv4 && cardDiv4.top <= vh / 4 && cardDiv4.top > -vh / 4) {
    //   setShowingSlide(3);
    // } else if (cardDiv5 && cardDiv5.top <= vh / 4 && cardDiv5.top > -vh / 4) {
    //   setShowingSlide(4);
    // }
    else if (cardDiv3 && cardDiv3.top <= -vh / 2) {
      setShowingSlide(5);
    }
  };

  React.useEffect(() => {
    const widths = [];
    const cardDiv1 = document.getElementById('service_card_1').getBoundingClientRect();
    servicesCards.forEach((ser, i) => {
      const item = document.getElementById(`card_title_${ser._id}`).getBoundingClientRect();
      widths[i] = item.left - cardDiv1.left;
    });
    widths[servicesCards.length] = vh;
    setHeadWidths(widths);

    windowGlobal.addEventListener('scroll', startOnScroll, { passive: true });
    return () => windowGlobal.removeEventListener('scroll', startOnScroll());
  }, []);

  const onTitleClick = (card, index) => {
    if (document.getElementById(card._id)) {
      document.getElementById(card._id).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    setShowingSlide(index);
  };

  let transitionValue = 0;

  if (showingSlide < servicesCards.length) {
    if (showingSlide > -1) transitionValue = -headWidths[showingSlide];
    else transitionValue = -headWidths[0];
  } else transitionValue = -headWidths[showingSlide - 1];

  if (vw < 992) containerTop = 10;
  else if (vw < 1048) containerTop = 50;
  else containerTop = 100;

  return (
    <div
      className="sticky"
      style={{
        top: 0,
        bottom: 0,
        padding: 0,
        overflow: 'hidden',
        width: '100%',
        backgroundColor: '#111f30',
      }}
    >
      <Container
        style={{
          position: showingSlide >= 0 && showingSlide < 5 ? 'fixed' : 'absolute',
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          top: containerTop,
          background: 'rgb(17, 31, 48,0.9)',
          transitionDuration: '1s',
          zIndex: 1000,
          transform: `translateX(${transitionValue}px)`,
        }}
        className="px-1 px-sm-3 px-md-5"
      >
        {servicesCards.map((ser, index) => (
          <button
            type="button"
            onClick={() => onTitleClick(ser, index)}
            id={`card_title_${ser._id}`}
            style={{
              opacity: showingSlide === index || (showingSlide === -1 && index === 0) ? 1 : 0.1,
              transition: 'opacity 1s',
              color: '#fff',
              minWidth: 'auto',
            }}
            className="p-3 link-no-style bg-none custom-button "
          >
            <h3 className="mb-0 text-nowrap">{ser.title}</h3>
          </button>
        ))}
      </Container>
      <div
        style={{
          height: '100%',
          minWidth: '100%',
        }}
      >
        {servicesCards.map((service, index) => (
          <Container key={service._id} id={service._id} className={`pt-10 pt-md-0`}>
            <Row
              style={{
                position: 'relative',
                // height: '100vh',
                width: '100%',
                flexShrink: 0,
                transition: 'opacity 1s',
                opacity: showingSlide === index || (showingSlide === -1 && index === 0) ? 1 : 0.1,
              }}
              noGutters
              className="align-items-center text-light flex-row-reverse flex-md-row"
            >
              <Col xs={12} md={6}>
                {/* <h1 className="text-nowrap" style={{ opacity: 0.1, position: 'absolute', top: '-3rem', left: 0 }}>
                  {service.title}
                </h1> */}
                <p>{service.description}</p>
                <div>
                  {service.co_services.map((coServices) => (
                    <p>-&nbsp;&nbsp;{coServices.name}</p>
                  ))}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div id={`img_${service._id}`} style={{ zIndex: 1 }} className="translate-image-out">
                  <Image image={images[service.img].childImageSharp.fluid} />
                </div>
              </Col>
            </Row>
          </Container>
        ))}
      </div>
    </div>
  );
};

const ServicesPage = () => {
  const images = useStaticQuery(graphql`
    query {
      websites: file(relativePath: { eq: "websites.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      chatBots: file(relativePath: { eq: "chatBots.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mobileApps: file(relativePath: { eq: "mobileApps.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      skillSet: file(relativePath: { eq: "skillSet.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout pageInfo={{ pageName: 'services' }} title="Services" description="We build web, mobile and conversational based digital products for businesses.">
        <SEO title="Services" />
        <div style={{ position: 'relative' }}>
          <ServiceSection images={images} />
        </div>
        <Jumbotron fluid className="m-0 bg-light">
          <Container>
            <Row
              noGutters
              style={{
                // height: '100vh',
                width: '100%',
              }}
              className="align-items-center"
            >
              <Col xs={12} md={6}>
                <h2>Techstack &amp; Skills</h2>
                <h6 className="text-left w-100 w-lg-75">We are javascript junkies all the way from frontend to backend. Node Js, React Js, React Native is our primary stack. Also we have an experienced dev ops team to go with it.</h6>
              </Col>
              <Col xs={12} md={6}>
                <div className="mr-auto" style={{ maxWidth: 400 }}>
                  <Image image={images.skillSet.childImageSharp.fluid} />
                </div>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </Layout>
    </>
  );
};

export default ServicesPage;
